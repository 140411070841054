<template>
  <div>
    <b-tabs
      pills
      fill
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="FileTextIcon" />
          <span>Katalog Bilgileri</span>
        </template>
        <validation-observer ref="simpleRules">
          <b-alert
            v-if="loading"
            show
            variant="primary"
          >
            <div class="alert-body">
              <pre-loading />
            </div>
          </b-alert>
          <template v-else>
            <clone-form />
            <div class="text-center">
              <b-button
                variant="primary"
                @click="cloneData"
              >
                <FeatherIcon icon="CopyIcon" />
                KOPYA OLUŞTUR
              </b-button>
            </div>
          </template>
        </validation-observer>
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="UserIcon" />
          <span>Müşteri Bilgileri</span>
        </template>
        <customer-info />
        <customer-invoice />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="ClockIcon" />
          <span>Müşteri Geçmişi</span>
        </template>
        <customer-history :customer-id="this.$route.params.id_customers" />
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import {
  BTabs, BTab, BAlert, BButton,
} from 'bootstrap-vue'
import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import PreLoading from '@/layouts/components/common/PreLoading.vue'
import CloneForm from '@/views/Admin/Catalogs/CloneForm.vue'
import CustomerInfo from '@/views/Admin/Customers/View/CustomerInfo.vue'
import CustomerInvoice from '@/views/Admin/Customers/View/CustomerInvoice.vue'
import CustomerHistory from '@/views/Admin/Customers/View/CustomerHistory.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'Add',
  components: {
    BTabs,
    BTab,
    BAlert,
    BButton,
    PreLoading,
    CloneForm,
    CustomerInfo,
    CustomerInvoice,
    CustomerHistory,
    ValidationObserver,
  },
  data() {
    return {
      required,
      loading: true,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['catalogs/dataItem']
    },
  },
  created() {
    this.$store.commit('catalogs/RESET_DATA_ITEM')
    this.getData()
    this.getCustomer()
    localize('tr')
    console.log(this.$route.params)
  },
  methods: {
    getData() {
      this.$store.dispatch('catalogs/getDataItem', { id: this.$route.params.id_catalogs })
        .then(response => {
          if (response) {
            this.loading = false
          }
        })
    },
    getCustomer() {
      this.$store.dispatch('customers/getDataItem', this.$route.params.id_customers)
      this.dataItem.id_customers = this.$route.params.id_customers
    },
    cloneData() {
      this.$store.dispatch('catalogs/cloneData', {
        id: this.dataItem.id,
        id_customers: this.$route.params.id_customers,
      })
        .then(response => {
          if (response.status) {
            this.showToast({
              variant: 'success', title: 'İşlem Hatası!', icon: 'CheckIcon', text: response.message,
            })
            this.$router.push(`/app/catalogs/edit/${response.id}`)
          } else {
            this.showToast({
              variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: response.message,
            })
          }
        })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
