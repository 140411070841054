<template>
  <b-card title="Kopya Oluştur">
    <b-list-group>
      <b-list-group-item>
        <div class="text-primary">
          Katalog Adı
        </div>
        <div>{{ dataItem.title }}</div>
      </b-list-group-item>
      <b-list-group-item>
        <div class="text-primary">
          Geçerlilik Tarihi
        </div>
        <div>{{ moment(dataItem.expiry_date).format('LL') }}</div>
      </b-list-group-item>
      <b-list-group-item>
        <div class="text-primary">
          Ürün Adedi
        </div>
        <div>{{ dataItem.items.length }} Adet</div>
      </b-list-group-item>
      <b-list-group-item>
        <div class="text-primary">
          Kopya Oluşturulacak Müşteri
        </div>
        <div>{{ customer.company }}</div>
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>
<script>
import { BCard, BListGroup, BListGroupItem } from 'bootstrap-vue'

export default {
  name: 'CloneForm',
  components: {
    BCard,
    BListGroup,
    BListGroupItem,
  },
  computed: {
    customer() {
      return this.$store.getters['customers/dataItem']
    },
    dataItem() {
      return this.$store.getters['catalogs/dataItem']
    },
  },
}
</script>
